:root {
  --color-link: #21BFAA;

  --color-background-brand-light: #21BFAA;
  --color-background-brand-dark: #303030;
  --color-text-brand-light: #FFFFFF;
  --color-text-brand-dark: #FFFFFF;

  --color-background-main-light: #FFFFFF;
  --color-background-main-dark: #121212;
  --color-text-main-light: #000000;
  --color-text-main-dark: #FFFFFF;

  --color-text-blurb-light: #707070;
  --color-text-blurb-dark: #999999;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  color: var(--color-text-main-light);
  background-color: var(--color-background-main-light);
  margin: 0px 0px 0px 0px;
}

a {
  color: var(--color-link);
}

.centerContainer {
  max-width: 750px;
  margin: 0px 20px;
}

.brandBackgroundContainer {
  color: var(--color-text-brand-light);
  background-color: var(--color-background-brand-light);
}

/* Header */
.headerText {
  margin: 0px;
  padding-top: 20px;
}

.subtitleText {
  margin-top: 5px;
  padding-bottom: 20px;
  font-weight: 400;
  font-size: 1.3em;
}

/* Service table */
.servicesTable {
  border-collapse: separate;
  border-spacing: 0px 8px;
}

.servicesTable a {
  text-decoration: none;
  color: var(--color-text-main-light);
}

.servicesTable th {
  text-align: left;
}

.spacerColumn {
  width: 10px;
}

.statusCell {
  text-align: center;
}

.chevronContainer {
  width: 20px;
}

.chevronContainer img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* App links */
.appLinksContainer {
  text-align: center;
  padding: 20px 0px;
}

.appBlurbHeader {
  margin-bottom: 0px;
}

.appBlurb {
  margin: 5px 10px 20px 10px;
  color: var(--color-text-blurb-light);
}

/* Support */
.supportContainer {
  padding: 4px 0px;
}

.supportContainer a {
  color: var(--color-text-brand-light);
}

/* Service Details */
.statusDetails {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 1em;
}

.statusDetails svg {
  flex-shrink: 0;
}

/* Responsive */
@media screen and (min-width: 750px) {
  .mainContent {
    display: flex;
    flex-direction: row;
  }

  .mainContent>div:first-child {
    width: 80%;
    margin-bottom: 10px;
  }

  .centerContainer {
    margin: 0px auto;
  }

  .servicesTable {
    margin-bottom: 10px;
  }

}

/* Dark mode */
@media (prefers-color-scheme: dark) {
  body {
    color: var(--color-text-main-dark);
    background-color: var(--color-background-main-dark);
  }

  .brandBackgroundContainer {
    color: var(--color-text-brand-dark);
    background-color: var(--color-background-brand-dark);
  }

  .servicesTable a {
    color: var(--color-text-main-dark);
  }

  .appBlurb {
    color: var(--color-text-blurb-dark);
  }

  .supportContainer a {
    color: var(--color-text-brand-dark);
  }
}